import dayjs from "dayjs";
import { useEffect } from "react";
import { Order, ReceiptSetting } from "../../types/student/order";
import { formatNumberWithCommas } from "../../services/helpers/parseData";
import { useAuth } from "../../hooks/useAuth";

type ReceiptPreviewProps = {
    handleClose?: any,
    receiptSettingData?: ReceiptSetting,
    receiptData?: Order,
    otherStyle?: string
}

const ReceiptPreview = (props: ReceiptPreviewProps) => {
    let { receiptData, handleClose, receiptSettingData, otherStyle } = props;
    const { user } = useAuth()

    const formatZipcode = (zipcode: string | null) => {
        if (!zipcode) return 'xxx-xxxx';
        const matches = zipcode.match(/^(\d{3})(\d{4})$/);
        if (matches) {
            return `${matches[1]}-${matches[2]}`;
        }

        return zipcode;
    }
    let zipcode = '';
    if (receiptSettingData && receiptSettingData.oz_postal_code) {
        zipcode = formatZipcode(receiptSettingData.oz_postal_code)
    }

    return (
        <>
            <div className={otherStyle} id="receipt-preview">
                <div
                    className="justify-center items-center flex outline-none focus:outline-none"
                >
                    <div className="relative w-full my-6 mx-auto shadow-lg">
                        <div className="border border-black relative flex flex-col w-auto bg-white outline-none focus:outline-none mx-[20px] my-[30px]">
                            <div
                                className="flex justify-center px-5 pt-6">
                                <h3 className="text-3xl font-semibold">
                                    領収書
                                </h3>
                            </div>
                            <div className="relative px-6 flex-auto">
                                <div className={'grid grid-cols-6 place-items-center gap-0'}>
                                    <div className={'col-start-1 col-end-1 row-start-2 border-b border-solid border-black rounded-t w-[150px] ml-[50px] flex justify-center'}>
                                        <div className="text-xl font-thin">
                                            {user.last_name && user.first_name ? user.last_name + user.first_name : '氏名 氏名'} 様
                                        </div>
                                    </div>
                                    <div className={'col-start-5 col-end-5 text-xs justify-self-end'}>No.</div>
                                    <div className={'col-start-6 col-end-6 text-xs'}>{receiptData?.code || "00000000"}</div>
                                    <div className={'col-start-5 col-end-5 text-xs justify-self-end'}>発行日</div>
                                    <div className={'col-start-6 col-end-6 text-xs'}>{receiptData?.receipt_public_at ? dayjs(receiptData?.receipt_public_at).format('YYYY/MM/DD') : dayjs().format('YYYY/MM/DD')}</div>
                                    <div className={'col-start-5 col-end-5 text-xs justify-self-end'}>領収日</div>
                                    <div className={'col-start-6 col-end-6 text-xs'}>{receiptData?.order_paid_at ? dayjs(receiptData?.order_paid_at).format('YYYY/MM/DD') : dayjs().format('YYYY/MM/DD')}</div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="w-[70%] px-5 pt-4 m-auto">
                                    <div className="flex items-center justify-center w-full border-b border-solid border-black text-center pb-[6px]">
                                        <p className="text-xl pr-[20px]">金額</p> 
                                        <p className="text-2xl">￥{receiptData?.total ? formatNumberWithCommas(receiptData.total) : "00,000-"}</p>
                                    </div>
                                    {/* fixed value */}
                                    {receiptSettingData?.oz_proviso ?
                                        <p className="text-[13px] max-w-[150px] whitespace-normal break-words">{receiptSettingData?.oz_proviso}</p> :
                                        <>
                                            <p className="text-[13px] pt-[5px] text-center">但し　商品代として上記正に領収いたしました。</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="relative px-6 pt-2 pb-5 flex-auto">
                                <div className={'grid grid-cols-2 place-items-center text-sm'}>
                                    <div className={'col-start-1 justify-self-start w-full pl-[30px] '}>
                                        <div className="justify self-start w-full text-sm">
                                            内訳
                                        </div>
                                        {/* fixed amount */}
                                        <div className={'border-b border-solid border-black rounded-t w-[70%] flex justify-center'}>
                                            <div className="flex justify-between w-[80%]">
                                                <div className="text-xs !leading-6">
                                                    税抜金額
                                                </div>
                                                <div className="text-xs !leading-6">
                                                    ￥{receiptData?.total ? formatNumberWithCommas(receiptData.total) : "00,000-"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'border-b border-solid border-black rounded-t w-[70%] flex justify-center'}>
                                            <div className="flex justify-between w-[80%]">
                                                <div className="text-xs !leading-6">
                                                    消費税（10%）
                                                </div>
                                                <div className="text-xs !leading-6">
                                                    ￥{receiptData?.total ? formatNumberWithCommas((receiptData.total * 10 / 100).toFixed()) : "3,000-"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-start-2 justify-self-start !pl-[30px]'}>
                                        <div className="justify self-start">
                                            {receiptSettingData?.oz_name ?? "株式会社パレットエデュケーション"}
                                        </div>
                                        <div className="justify self-start">
                                            {zipcode ? `〒${zipcode}` : '〒123=4567'}
                                        </div>
                                        <div className="justify self-start text-[13px]">
                                            {`${receiptSettingData?.oz_province || ''} ${receiptSettingData?.oz_ward || 'XX'} ${receiptSettingData?.oz_address || 'XX1-2-34'}`}
                                        </div>
                                        <div className="justify self-start text-[13px]">
                                            {receiptSettingData?.oz_building ?? "パレットLMSビル３階"}
                                        </div>
                                        <div>
                                            TEL {receiptSettingData?.oz_tel ?? '03-0123-4567'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute right-[30px] bottom-[20px] h-[80px] w-[80px]">
                                {receiptSettingData && (receiptSettingData.image || receiptSettingData?.oz_seal_url) &&
                                    <img
                                        src={
                                            receiptSettingData && receiptSettingData.image
                                                ? URL.createObjectURL(receiptSettingData.image)
                                                : receiptSettingData?.oz_seal_url
                                        }
                                        alt="Image"
                                        className="mix-blend-multiply object-fill h-full w-full"
                                    ></img>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReceiptPreview
