import { useEffect, useState } from "react";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { STUDENT_THEME_COLOR_DEFAULT } from "../../../services/constants/admin/pages/student_theme";
import { getTimeObjectFromMilliseconds } from "../../../services/helpers/formatTime";
import { getPercent } from "../../../services/helpers/parseData";
import { LectureStatistics } from "../../../types/student/lecture";
import ParagraphExpander from "../../admin/commons/ParagraphExpander";
import CircularProgress from "../../commons/charts/CircularProgress";

const CourseStatus = ({
  statistics,
  waitingApi,
}: {
  statistics: LectureStatistics;
  waitingApi: boolean;
}) => {
  const unitsCompleted = statistics.unit_statistical?.total_unit_completed || 0;
  const unitTotal = statistics.unit_statistical?.total_unit || 0;
  const unitDonePercentage = getPercent(unitsCompleted, unitTotal);
  const chaptersCompleted =
    statistics.chapter_statistical?.total_chapters_completed || 0;
  const chapterTotal = statistics.chapter_statistical?.total_chapters || 0;
  const chapterDonePercentage = getPercent(chaptersCompleted, chapterTotal);
  const lectureLearningTimeSevenDays = getTimeObjectFromMilliseconds(
    Number(statistics.learning_time_in_the_lecture?.seven_days_ago || 1)
  );
  const lectureLearningTimeThirtyDays = getTimeObjectFromMilliseconds(
    Number(statistics.learning_time_in_the_lecture?.thirty_days_ago || 1)
  );
  const lectureLearningTimeTotal = getTimeObjectFromMilliseconds(
    Number(statistics.learning_time_in_the_lecture?.whole_lecture || 1)
  );
  const { studentTheme } = useStudentThemeContext();
  const [radiusProgress, setRadiusProgress] = useState(175);

  const checkScreenWidth = () => {
    if (window.innerWidth <= 375) {
      setRadiusProgress(160);
    } else {
      setRadiusProgress(175);
    }
  };
  window.addEventListener("resize", checkScreenWidth);
  document.addEventListener("DOMContentLoaded", checkScreenWidth);
  window.addEventListener("load", checkScreenWidth);

  return (
    <>
      {" "}
      {!waitingApi ? (
        <div className="animate-[show_0.5s_ease-in-out]">
          {" "}
          <div className="max-sm:flex max-sm:justify-center max-sm:w-full">
            <div
              style={{
                backgroundColor:
                  studentTheme.lecture_top_color ||
                  STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
              }}
              className={`w-auto max-w-[500px] px-[15px] py-[5px] h-[30px] bg-success text-white rounded-[5px] inline-block mb-[26px] mt-[30px]`}
            >
              <div className="font-[700] text-white text-[14px] leading-[20px] tracking-[0.02em] max-w-[300px] truncate">
                {statistics?.lecture_info?.name
                  ? `（${statistics?.lecture_info?.name}）`
                  : ""}{" "}
                の学習状況
              </div>
            </div>
          </div>
          <section className="px-[16px] py-[20px] mb-[15px] ">
            <div className="flex gap-[35px] justify-between max-sm:flex-col">
              <div className="flex sm:gap-[35px] justify-center w-full">
                <div className="w-full max-w-[200px] min-w-[200px] h-[200px] relative max-sm:min-w-[180px] max-sm:h-[180px]">
                  <div className="absolute top-0 left-0 w-full h-[200px] rounded-full bg-white flex items-center justify-center max-sm:min-w-[180px] max-sm:h-[180px]">
                    <div className="text-center">
                      <div className="text-[14px] font-[700] leading-[20px] tracking-[0.02em] text-secondary mb-[6.86px]">
                        修了講座数
                      </div>
                      <div
                        className={`text-[12px] font-[500] leading-[100%] text- mb-[8px]`}
                      >
                        <span
                          style={{
                            color:
                              studentTheme.lecture_top_color ||
                              STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
                          }}
                          className="text-[35px] font-[900] leading-[100%] with_roboto text-success"
                        >
                          {chaptersCompleted || 0}
                        </span>
                        <span className="text-[16px] font-[700] text-secondary-light leading-[100%] with_roboto">
                          / {chapterTotal || 0}
                        </span>
                      </div>
                      <div className="text-[12px] leading-[100%] tracking-[0.02em] text-secondary">
                        章
                      </div>
                    </div>
                  </div>
                  <CircularProgress
                    green={true}
                    sqSize={radiusProgress}
                    strokeWidth={17}
                    percentage={chapterDonePercentage}
                    className="absolute top-[12px] left-[12.5px]"
                  />
                </div>
                <div className="w-full max-w-[200px] min-w-[200px] h-[200px] relative max-sm:min-w-[180px] max-sm:h-[180px]">
                  <div className="absolute top-0 left-0 w-full h-[200px] rounded-full bg-white flex items-center justify-center max-sm:min-w-[180px] max-sm:h-[180px]">
                    <div className="text-center">
                      <div className="text-[14px] font-[700] leading-[20px] tracking-[0.02em] text-secondary mb-[6.86px]">
                        学習状況
                      </div>
                      <div
                        style={{
                          color:
                            studentTheme.lecture_top_color ||
                            STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
                        }}
                        className={`text-[12px] font-[500] leading-[100%] text-success mb-[8px]`}
                      >
                        <span className="text-[35px] font-[700] leading-[100%] with_roboto">
                          {unitsCompleted || 0}
                        </span>
                        <span className="text-[16px] font-[700] text-secondary-light leading-[100%] with_roboto">
                          / {unitTotal || 0}
                        </span>
                      </div>
                      <div className="text-[12px] leading-[100%] tracking-[0.02em] text-secondary">
                        単元
                      </div>
                    </div>
                  </div>
                  <CircularProgress
                    green={true}
                    sqSize={radiusProgress}
                    strokeWidth={17}
                    percentage={unitDonePercentage}
                    className="absolute top-[12px] left-[12.5px]"
                  />
                </div>
              </div>
              <div className="w-full flex max-sm:justify-center sm:ml-[10px] sm:justify-end justify-end">
                <div className="w-full max-w-[230px] h-[200px] relative pt-[10px]">
                  <div className="text-[14px] text-secondary font-[700] flex items-center justify-center mb-[10px]">
                    この講座の学習時間
                  </div>
                  <div className="flex min-h-[45px] mb-[2px] border-b-[1.5px] border-t-[1.5px]">
                    <div
                      style={{
                        backgroundColor:
                          studentTheme.lecture_top_color ||
                          STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
                      }}
                      className="font-[700] text-[12px] text-white leading-[100%] w-full max-w-[90px] bg-success flex items-center justify-end pr-[9px]"
                    >
                      <div className="">過去7日間</div>
                    </div>
                    <div className="text-secondary text-[10px] font-[500] w-full flex items-center justify-end whitespace-nowrap pr-[5px]">
                      <span className="text-[16.8px] mr-[2.1px] with_roboto">
                        {lectureLearningTimeSevenDays.hours || 0}
                      </span>
                      時間
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeSevenDays.minutes || 0}
                      </span>
                      分
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeSevenDays.seconds || 0}
                      </span>
                      秒
                    </div>
                  </div>
                  <div className="flex min-h-[45px] mb-[2px] border-b-[1.5px]">
                    <div
                      style={{
                        backgroundColor:
                          studentTheme.lecture_top_color ||
                          STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
                      }}
                      className="font-[700] text-[12px] text-white leading-[100%] w-full max-w-[90px] bg-success flex items-center justify-end pr-[9px]"
                    >
                      <div className="">過去30日間</div>
                    </div>
                    <div className="text-secondary text-[10px] font-[500] w-full flex items-center justify-end whitespace-nowrap pr-[5px]">
                      <span className="text-[16.8px] mr-[2.1px] with_roboto">
                        {lectureLearningTimeThirtyDays.hours || 0}
                      </span>
                      時間
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeThirtyDays.minutes || 0}
                      </span>
                      分
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeThirtyDays.seconds || 0}
                      </span>
                      秒
                    </div>
                  </div>
                  <div className="flex min-h-[45px] mb-[2px] border-b-[1.5px]">
                    <div
                      style={{
                        backgroundColor:
                          studentTheme.lecture_top_color ||
                          STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
                      }}
                      className="font-[700] text-[12px] text-white leading-[100%] w-full max-w-[90px] bg-success flex items-center justify-end pr-[9px]"
                    >
                      <div className="">総学習時間</div>
                    </div>
                    <div className="text-secondary text-[10px] font-[500] w-full flex items-center justify-end whitespace-nowrap pr-[5px]">
                      <span className="text-[16.8px] mr-[2.1px] with_roboto">
                        {lectureLearningTimeTotal.hours || 0}
                      </span>
                      時間
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeTotal.minutes || 0}
                      </span>
                      分
                      <span className="text-[16.8px] mx-[2.1px] with_roboto">
                        {lectureLearningTimeTotal.seconds || 0}
                      </span>
                      秒
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-[26px] text-[12px] font-[400] leading-[28.9px] text-justify">
              <ParagraphExpander
                text={statistics.lecture_info?.description || ""}
              />
            </div>
          </section>
        </div>
      ) : (
        <div className="skeleton w-full min-h-[400px] bg-secondary-lighter mb-[20px]"></div>
      )}
    </>
  );
};

export default CourseStatus;
