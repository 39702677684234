import { PATH_STUDY_TOOL, ROUTE } from "../route/router";

const STUDENT_MENU = {
  PROFILE: '会員情報',
  MY_PAGE: 'マイページトップ',
  COURSE_LIST: '受講コース一覧',
  LEARNING_HISTORY: '学習履歴',
  LEARNING_REPORT: '学習レポート',
  SCHEDULE: 'スケジュール',
  MEMO: 'メモ',
  REVIEW: '復習',
  NOTICE: 'お知らせ',
  COMMUNITY: 'コミュニティ',
  INBOX: '受信ボックス',
  FUNCTION_LIST: '機能一覧',
  SETTING: '設定',
  LOGOUT: 'ログアウト',
};

const STUDENT_MENU_DATA = [
  {
    label: STUDENT_MENU.PROFILE,
    href: '/profile'
  },
  {
    label: STUDENT_MENU.MY_PAGE,
    href: ROUTE.MY_PAGE.DEFAULT
  },
  {
    label: STUDENT_MENU.COURSE_LIST,
    href: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.COURSE_LIST}`
  },
  {
    label: STUDENT_MENU.LEARNING_HISTORY,
    href: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.STUDY_HISTORY}`
  },
  // Menu Header Items hidden for later phases
  {
    label: STUDENT_MENU.LEARNING_REPORT,
    href: '/#'
  },
  {
    label: STUDENT_MENU.SCHEDULE,
    href: PATH_STUDY_TOOL.SCHEDULE.DEFAULT
  },
  {
    label: STUDENT_MENU.MEMO,
    href: PATH_STUDY_TOOL.MEMO
  },
  {
    label: STUDENT_MENU.REVIEW,
    href: '/#'
  },
  // {
  //   label: STUDENT_MENU.NOTICE,
  //   href: '/#'
  // },
  // {
  //   label: STUDENT_MENU.COMMUNITY,
  //   href: '/#'
  // },
  // {
  //   label: STUDENT_MENU.INBOX,
  //   href: '/#'
  // },
  {
    label: STUDENT_MENU.LOGOUT, 
    href: '/logout'
  },
];

const STUDENT_MENU_MOBILE_DATA = [
  {
    label: STUDENT_MENU.PROFILE,
    href: '/profile'
  },
  {
    label: STUDENT_MENU.MY_PAGE,
    href: ROUTE.MY_PAGE.DEFAULT
  },
  {
    label: STUDENT_MENU.COURSE_LIST,
    href: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.COURSE_LIST}`
  },
  {
    label: STUDENT_MENU.LEARNING_HISTORY,
    href: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.STUDY_HISTORY}`
  },
    {
    label: STUDENT_MENU.NOTICE,
    href: '/#'
  },
  {
    label: STUDENT_MENU.COMMUNITY,
    href: '/#'
  },
  {
    label: STUDENT_MENU.INBOX,
    href: '/#'
  },
  {
    label: STUDENT_MENU.FUNCTION_LIST,
    href: '/#'
  },
  {
    label: STUDENT_MENU.SETTING,
    href: '/#'
  },
];

const STUDENT_MENU_BORDER_INDEX = [0, 3, 7, 10];

export { STUDENT_MENU, STUDENT_MENU_BORDER_INDEX, STUDENT_MENU_DATA, STUDENT_MENU_MOBILE_DATA };
